<template>
  <div class="page-wrapper">
    <v-card class="content-card">
      <v-card-title class="card-title">
        <h2 v-t="'Orders.Orders'"></h2>
        <v-spacer></v-spacer>
        <v-row class="form-row">
          <v-text-field :min="1" type="number" v-model="nbShown" class="form-field" label="Files per page" outlined></v-text-field>
          <v-text-field v-model="search" class="search-field" label="Search" append-outer-icon="mdi-magnify" @click:append-outer="searchRef" clearable outlined></v-text-field>
        </v-row>
      </v-card-title>
      <div class="total-files">{{ $t('Orders.TotalFiles') }} {{ orders.total }}</div>
      <div class="pagination-container">
        <div class="pagination">
          <v-pagination v-model="currentPage" total-visible="7" :length="pages"></v-pagination>
        </div>
      </div>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class='table-header' v-t="'Orders.Name'"></th>
              <th class='table-header' v-t="'Orders.FirstDownloadDatePrint'"></th>
              <th class='table-header' v-t="'Orders.FirstDownloadDateProof'"></th>
              <th class='table-header' v-t="'Orders.DownloadPrintFile'"></th>
              <th class='table-header' v-t="'Orders.DownloadProofFile'"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='file in shownOrdersFiles' :key='file.id' :id="file.id" :ref="file.id">
              <td>{{ file.displayFileName }}</td>
              <td>{{ file.printFileDownloadDate }}</td>
              <td>{{ file.proofFileDownloadDate }}</td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="black" icon @click="downloadPrintFile(file)">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Download print file</span>
                </v-tooltip>
              </td>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="black" icon @click="downloadProofFile(file)">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Download proof file</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-pagination v-model="currentPage" total-visible="7" :length="pages"></v-pagination>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Orders',
  data () {
    return {
      currentPage: 1,
      nbShown: 20,
      loading: false,
      search: '',
      total: 0,
      documents: [],
      orders: []
    }
  },
  async created () {
    await this.getOrders()
    this.documents = this.orders.documents
    this.total = this.orders.total
  },
  computed: {
    shownOrdersFiles () {
      if (!this.documents) {
        return []
      }
      return this.documents
        .slice((this.currentPage - 1) * this.nbShown, this.currentPage * this.nbShown)
        .map(file => ({
          ...file,
          displayFileName: file.printFileName || file.proofFileName
        }))
    },
    pages () {
      if (!this.documents) {
        return 0
      }
      return Math.ceil(this.documents.length / this.nbShown)
    }
  },
  watch: {
    search: function (val) {
      this.searchRef()
    },
    nbShown: function (val) {
      this.nbShown = val <= 0 ? 1 : val
      this.currentPage = 1
    }
  },
  methods: {
    async downloadPrintFile (file) {
      try {
        await this.$store.dispatch('downloadPrintFile', file)
      } catch (err) {
        const errorString = await err.response.data.text()
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + JSON.parse(errorString))
        })
      }
    },
    async downloadProofFile (file) {
      try {
        await this.$store.dispatch('downloadProofFile', file)
      } catch (err) {
        const errorString = await err.response.data.text()
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + JSON.parse(errorString))
        })
      }
    },
    searchRef () {
      if (!this.search) {
        this.documents = this.orders.documents
        this.total = this.documents.length
        return
      }
      this.currentPage = 1
      const searchText = this.search.trim().toLowerCase()
      this.documents = this.orders.documents.filter((document) => {
        return document.printFileName.toLowerCase() === searchText
      })
      this.total = this.documents.length
    },
    async getOrders () {
      await this.$store.dispatch('getAllOrders')
      this.orders = this.$store.getters['GET_ALL_ORDERS']
      this.documents = this.orders.documents
    }
  }
}
</script>

<style scoped>
  .page-wrapper {
    padding: 5px;
  }

  .content-card {
    margin: auto;
    padding: 3px;
  }

  .card-title {
    display: flex;
    align-items: baseline;
  }

  .form-row {
    display: flex;
    justify-content: flex-end;
  }

  .form-field {
    width: 10%;
    margin-left: 40%;
  }

  .search-field {
    margin-right: 5%;
    margin-left: 1%;
  }

  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .total-files {
    text-align: left;
    min-width: fit-content;
  }

  .pagination {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th, td {
    padding: 10px;
    text-align: center !important;
  }

  .table-header {
    width: 20%;
  }

  .v-pagination {
    margin: 0 auto;
  }
</style>
